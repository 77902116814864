<template>
  <div class="index-body">
    <div class="brief-introduction" :style="{ display: active ? 'flex' : '' }">
      <section class="info">
        <figure>
          <img src="../../assets/recommend/managineInfo.png" alt="" />
        </figure>
        <ul>
          <li>ISSN：1007-4295</li>
          <li>主管：中共中央党史和文献研究院</li>
          <li>主办：中国中共党史学会</li>
          <li>承办：中共党史出版社</li>
        </ul>
      </section>
      <section class="title">
        <figure>
          <img src="../../assets/recommend/magazinePic.png" alt="" />
        </figure>
        <div class="infomsg">
          <div class="infoTitle">期刊标题（例：2022/05)</div>
          <div class="intro">
            《百年潮》杂志是由中共中央党史和文献研究院主管、中国中共党史学会主办、中共党史出版社承办的大型时政文史月刊，创刊于1997年。《百年潮》杂志以“揭示历史真相，展现世纪风云”为宗旨，以“信史、实学、新知、美文”为风格，记述百年以来尤其是中国共产党领导各族人民争取民族独立、人民解放和国家富强、人民幸福的历史进程中的重大历史事件、重要历史人物。刊物的主要栏目有：本刊特稿、人物春秋、往事回首、史事忆念、史海钩沉、史事本末、口述历史、名人一瞥等.
          </div>
        </div>
      </section>
    </div>
    <div class="mian-body">
      <div class="navigation">
        <span @click="goMagazine">期刊</span>
        <i class="el-icon-arrow-right"></i>
        <span class="nav_info">
          {{
            dataForm.magazineNumberType == 0
              ? dataForm.magazineTitle &&
                dataForm.magazineTitle.substring(0, 8) +
                  dataForm.year +
                  `第${
                    dataForm.magazineNumber > 9
                      ? dataForm.magazineNumber
                      : "0" + dataForm.magazineNumber
                  }期`
              : dataForm.magazineTitle +
                dataForm.year +
                dataForm.magazineTotalNumber
          }}
        </span>
      </div>
      <div class="sidebar">
        <el-menu
          class="el-menu-vertical-demo"
          :default-active="activeMenuItem"
          @mouseleave="mouseout()"
        >
          <el-menu-item
            :index="'' + item.year"
            v-for="item in yearsList"
            :key="item.id"
            @mouseenter.native="mouseover(item.id)"
          >
            <span>{{ item.year }}</span>
            <ul
              v-if="item.id == activeIndex"
              class="mouseout"
              @mouseleave="mouseout()"
            >
              <li
                v-for="child in item.children"
                :class="dataForm.id == child.id ? 'active' : ''"
                @click="selectMagazineNumber(child.id, child)"
                :key="child.id"
              >
                {{
                  child.magazineNumberType == 1
                    ? child.magazineTotalNumber
                    : child.year +
                      `年-${
                        child.magazineNumber > 9
                          ? child.magazineNumber
                          : "0" + child.magazineNumber
                      }`
                }}
              </li>
            </ul>
          </el-menu-item>
        </el-menu>
      </div>
      <div
        class="sidebar sidebar2"
        @click="reset"
        :style="{ display: show ? 'block' : '' }"
      >
        <div class="sidebarInner" @click="preventdefault($event)">
          <div class="sidebarTop">
            <i class="el-icon-arrow-left" @click="goback"></i>
            <span>期刊分类</span>
          </div>
          <el-menu
            class="el-menu-vertical-demo"
            :default-active="'' + dataForm.id"
          >
            <el-submenu
              :index="'' + item.year"
              v-for="item in yearsList"
              :key="item.id"
            >
              <template slot="title">
                <span>{{ item.year }}({{ item.children.length }})</span>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  :index="'' + child.id"
                  v-for="child in item.children"
                  @click="selectMagazineNumber(child.id, child)"
                  :key="child.id"
                >
                  {{
                    child.magazineNumberType == 1
                      ? child.magazineTotalNumber
                      : child.year +
                        `年-${
                          child.magazineNumber > 9
                            ? child.magazineNumber
                            : "0" + child.magazineNumber
                        }`
                  }}</el-menu-item
                >
              </el-menu-item-group>
            </el-submenu>
          </el-menu>

          <div class="buttons">
            <div class="reset" @click="reset">取消</div>
            <div class="confirm" @click="confirm">确认</div>
          </div>
        </div>
      </div>

      <div class="container">
        <section
          class="button"
          :style="{
            'box-shadow': active
              ? ' 0rem 0.02rem 0.06rem 0rem rgba(0,0,0,0.1)'
              : '',
          }"
        >
          <div class="select" @click="showSelect">
            <section class="icon">
              <svg
                t="1685339716433"
                class="iconselect"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="6967"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                :width="'.24rem'"
                :height="'.24rem'"
              >
                <path
                  d="M855.342766 151.46262c-6.056949-11.851932-18.984377-19.393699-33.219589-19.393699L101.903901 132.068921c-14.360056 0-27.412326 7.704472-33.396621 19.744693-5.988388 12.015661-3.845585 26.157753 5.520737 36.192294l255.896134 274.308483 0 309.339324c0 12.847609 7.895831 24.602328 20.389376 30.328749l189.116195 86.432535c5.154393 2.371 10.771321 3.515057 16.33913 3.515057 6.541997 0 13.090133-1.607614 18.926048-4.797259 10.718109-5.945409 17.427928-16.503882 17.809621-28.037567l12.957103-396.767536 245.078765-274.90507C859.543438 177.316451 861.425298 163.313529 855.342766 151.46262zM520.773827 804.275693l-117.384477-53.647851L403.38935 483.628836l127.858016 0L520.773827 804.275693zM550.774095 416.986019c-1.963725-0.299829-3.761674-1.090844-5.809309-1.090844L383.519814 415.895175 181.938726 199.803605l562.427506 0L550.774095 416.986019zM685.454494 524.008498l273.392624 0 0 59.759035-273.392624 0 0-59.759035ZM685.454494 654.104485l273.392624 0 0 59.759035-273.392624 0 0-59.759035ZM685.454494 773.618463l273.392624 0 0 59.759035-273.392624 0 0-59.759035Z"
                  p-id="6968"
                ></path>
              </svg>
            </section>
            <span>{{ dataForm.year }}</span>
          </div>

          <div class="time" @click="showmsg">
            <figure>
              <img src="../../assets/home/magazineLogo.png" alt="" />
            </figure>
            <i class="el-icon-arrow-down el-icon--right" v-if="!active"></i>

            <i class="el-icon-arrow-up el-icon--right" v-if="active"></i>
          </div>
        </section>

        <figure>
          <img :src="dataForm.coverUrl" alt="" />
        </figure>
        <div class="content">
          <section class="title">
            {{
              dataForm.magazineTitle && dataForm.magazineTitle.substring(0, 8)
            }}
            &nbsp;

            {{
              dataForm.magazineNumberType == 0
                ? +dataForm.year +
                  `年第${
                    dataForm.magazineNumber >= 9
                      ? dataForm.magazineNumber
                      : "0" + dataForm.magazineNumber
                  }期`
                : dataForm.year + "年"
            }}
            <span class="magazine_number">
              {{
                dataForm.magazineNumberType == 0
                  ? `总第${dataForm.magazineTotalNumber}期`
                  : dataForm.magazineTotalNumber
              }}
            </span>
          </section>

          <section class="price">
            <div><span>定价：￥</span>{{ dataForm.price }}</div>
          </section>
          <section class="synopsis">
            <el-tabs v-model="activeName">
              <el-tab-pane
                label="本期看点"
                v-if="dataForm.watchingFocus"
                name="first"
              >
                <div class="ql-container ql-snow">
                  <div class="ql-editor">
                    <div
                      v-html="
                        dataForm.watchingFocus && decode(dataForm.watchingFocus)
                      "
                    ></div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                label="目录"
                :name="dataForm.watchingFocus ? 'second' : 'first'"
              >
                <div class="ql-container ql-snow">
                  <div class="ql-editor">
                    <div
                      v-html="dataForm.catalogue && decode(dataForm.catalogue)"
                    ></div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  name: "vue-index",

  data() {
    return {
      msg: "",
      show: false,
      active: false,
      activeMagazineNumber: null,
      yearsList: [],
      dataFormPages: [],
      activeIndex: 0,
      ID: "",
      CHILD: "",
      activeName: "first",
      dataForm: {
        //   year:2023,
        // saveStatus:1
        limit: 10000,
      },
      activeMenuItem: "",
      dataList: [],
    };
  },
  async mounted() {
    new Swiper(".swiper-container", {
      loop: true,
      // // 如果需要分页器
      // pagination: '.swiper-pagination',
      // // 如果需要前进后退按钮
      // nextButton: '.swiper-button-next',
      // prevButton: '.swiper-button-prev',
      // // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
    });
    await this.query();
    this.queryAll();
    let datalist = this.dataList.filter((e) => {
      return e.id == this.$route.query.id;
    });
    this.dataForm = datalist[0];
    this.activeMenuItem = this.dataForm.year;
    console.log(
      datalist,
      this.dataForm,
      this.dataList,
      this.$route.query.id,
      "dataform"
    );
  },
  methods: {
    goDetail() {
      this.$router.push({ path: "/bookdetail" });
    },
    preventdefault(event) {
      console.log("zuzhizuzhi");
      event.stopPropagation();
    },
    goback() {
      this.show = !this.show;
    },
    showmsg() {
      this.active = !this.active;
      console.log(this.active);
    },
    showSelect() {
      this.show = !this.show;
    },
    goMagazine() {
      this.$router.push({
        path: "/magazine",
        query: {},
      });
    },
    decode(base64) {
      // 对base64转编码
      // var decode = atob(base64)
      // // 编码转字符串
      // var str = decodeURI(decode)
      return base64;
    },
    mouseover(id) {
      console.log("123454");
      this.activeIndex = id;
      console.log("123454", id);
    },
    mouseout() {
      console.log("6666");
      this.activeIndex = null;
    },
    reset() {
      // this.dataForm = {}
      // this.query()
      this.show = !this.show;
    },
    confirm() {
      this.activeMagazineNumber = this.ID;
      this.dataForm = this.CHILD;
      this.activeIndex = null;
      this.activeMenuItem = this.CHILD.year;
      this.query();
      this.show = !this.show;
    },
    selectMagazineNumber(id, child) {
      if (
        navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        this.ID = id;
        this.CHILD = child;
      } else {
        this.activeMagazineNumber = id;
        this.dataForm = child;
        this.activeIndex = null;
        this.activeMenuItem = child.year;
      }
    },
    selectYears(list) {
      this.yearsList = [];
      let obj = {};
      this.yearsList = list.reduce(function (item, next) {
        obj[next.year] ? "" : (obj[next.year] = true && item.push(next));
        return item;
      }, []);
      this.yearsList.sort((a, b) => b.year - a.year);

      list.forEach((lis) => {
        this.yearsList.map((year) => {
          if (year.year == lis.year) {
            if (!year.children) {
              year.children = [];
            }
            year.children.push(JSON.parse(JSON.stringify(lis)));

            return;
          }
        });
      });
      console.log(this.yearsList, list, "yearslist");
    },
    selectType(status) {
      console.log("status", status);
      if (status) {
        this.dataFrom.publishRange = status;
      } else {
        this.dataFrom.publishRange = null;
      }

      this.query();
    },
    async query() {
      console.log("resdata");
      let status = null;
      if (self.frameElement && self.frameElement.tagName == "IFRAME") {
        status = null;
      } else {
        status = 1;
      }

      const { data: res } = await this.$http.get("/magazine/homePage", {
        params: {
          ...this.dataForm,
          status: status,
        },
      });
      // .then(({ data: res }) => {
      //   if (res.code !== 0) {
      //     this.dataList = [];
      //     this.total = 0;
      //     return this.$message.error(res.msg);
      //   }
      this.dataList = res.data.list;
      // this.selectYears(this.dataList);
      // })
      // .catch((err) => {
      //   console.log(err, "err");
      // });
    },
    async queryAll() {
      console.log("resdata");
      let status = null;
      if (self.frameElement && self.frameElement.tagName == "IFRAME") {
        status = null;
      } else {
        status = 1;
      }

      const { data: res } = await this.$http.get("/magazine/homePage", {
        params: {
          status: status,
          limit: 1000,
        },
      });

      if (res.code !== 0) {
        this.dataList = [];
        this.total = 0;
        return this.$message.error(res.msg);
      }
      console.log(res.data.list, "res.data.list");
      this.selectYears(res.data.list);
    },
  },
};
</script>

<style lang="scss" scoped>
.mouseout {
  position: absolute;
  left: 2.4rem;
  top: 0rem;
  width: 2.4rem;

  display: flex;
  flex-wrap: wrap;
  background: #eeeeee;
  opacity: 0.9;
  z-index: 9;
  li {
    width: 1.2rem;
    text-align: center;
  }
  .active {
    color: var(--backgroundColor);
  }
}
.mian-body {
  position: relative;
  top: 0.68rem;
  margin-top: 0.56rem;
  margin-bottom: 0.92rem;

  // height: 8.65rem;
  padding: 0 0.64rem;
  .magazine_number {
    color: rgba(178, 1, 3, 1);
    margin-left: 0.1rem;
  }
  .navigation {
    position: absolute;
    left: 0.64rem;
    top: -0.44rem;
    span {
      font-size: 0.14rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      line-height: 0.2rem;
      cursor: pointer;
    }
    .nav_info {
      font-size: 0.14rem;

      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 0.2rem;
    }
    i {
      color: rgba(178, 1, 3, 1);
      font-size: 0.16rem;
      margin: 0.05rem;
      font-weight: 600;
    }
  }

  .topone {
    padding-left: 0.32rem !important;
    height: 0.56rem;
    margin-bottom: 0;
  }
  .topone:hover {
    background: #ffffff;
  }
  .topone.is-active {
    background: #ffffff;
  }
  .topone.is-active::after {
    display: none;
  }
}
.mian-body {
  display: flex;
  justify-content: space-between;
}
.sidebar {
  width: 2.4rem;
  height: 8.65rem;
  background: #ffffff;
}
.sidebar2 {
  display: none;
}
.container {
  position: relative;
  box-sizing: border-box;
  width: 10.56rem;
  // height: 8.65rem;
}
/deep/ .el-menu {
  border: none;
}

/deep/ .el-icon-arrow-down:before {
  color: var(--backgroundColor);
}
/deep/ .el-menu-item {
  position: relative;
  font-weight: 500;
  font-size: 0.14rem;
  color: rgba(0, 0, 0, 0.9);
  height: 0.48rem;
  line-height: 0.48rem;
  padding-left: 0.64rem !important;
  position: relative;
  margin-bottom: 0.08rem;
}
/deep/ .el-submenu__title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  height: 0.56rem;
  line-height: 0.48rem;
  padding-left: 0.32rem !important;
}
/deep/.el-menu-item:hover {
  background: #eeeeee;
}
/deep/.el-submenu__title:hover {
  background: #ffffff;
}
/deep/ .el-menu-item.is-active {
  background: rgba(195, 40, 43, 0.1);
}

.el-menu-item.is-active::after {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  content: "";
  width: 0.04rem;
  height: 0.48rem;
  background: #c3282b;
}

.pagination {
  position: absolute;
  right: 0.32rem;
  margin-top: 0.47rem;
  display: flex;
  align-items: center;
  span {
    font-size: 0.14rem;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 0.2rem;
    margin-right: 0.16rem;
  }
  .go {
    margin-right: 0.08rem;
  }
  .page {
    margin-left: 0.14rem;
  }
  /deep/ .el-input {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }
  /deep/ .el-input__inner {
    width: 0.32rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
  }

  /deep/.el-button {
    width: 0.6rem;
    height: 0.32rem;
    background: #ffffff;
    border-radius: 0.04rem;
    border: 0.01rem solid #eeeeee;
    // line-height: .02rem;

    padding: 0;
  }
  /deep/.el-button span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
  }
}

.brief-introduction {
  position: relative;
  top: -1.2rem;
  margin: 0 0.64rem;
  width: 13.1rem;
  height: 1.6rem;
  background: #ffffff;
  border-radius: 0.02rem 0.02rem 0rem 0.02rem;
  margin-bottom: 0.16rem;
  display: none;
  .info {
    padding: 0.3rem 0.32rem;
    border-right: 2px dashed rgba(238, 238, 238, 1);
    width: 50%;
    display: flex;
    figure {
      width: 1.4rem;
      height: 0.92rem;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: 0.24rem;
      align-items: center;
      li {
        width: 2.04rem;
        font-size: 0.14rem;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 0.22rem;
      }
    }
  }
  .title {
    padding: 0.3rem 0.32rem;

    width: 50%;
    display: flex;
    figure {
      width: 0.68rem;
      height: 0.94rem;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .infomsg {
      margin-left: 0.23rem;
      .infoTitle {
        line-height: 0.33rem;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.9);

        font-size: 0.16rem;
      }
      .intro {
        font-size: 0.14rem;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 0.22rem;
      }
    }
  }
}
.container {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  display: flex;
  justify-content: space-between;
  figure {
    width: 2.8rem;
    height: 3.5rem;
    border-radius: 0.01rem;
    border: 0.01rem solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 95%;
    }
  }
  .button {
    display: none;
  }
  .content {
    width: 6.48rem;
  }
  .title {
    font-size: 0.24rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    line-height: 0.32rem;
    margin-bottom: 0.24rem;
  }
  .source {
    box-sizing: border-box;
    width: 8.68rem;
    height: 1.08rem;
    background: #f8f8f8;
    border-radius: 0.02rem;
    padding: 0.24rem 0.4rem;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 4.34rem;
      font-size: 0.14rem;
      margin-bottom: 0.16rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 0.22rem;
    }
    li:nth-child(2n) {
      width: 3.54rem;
    }
  }
  .price {
    margin-top: 0.24rem;
    margin-bottom: 0.24rem;
    font-size: 0.16rem;
    display: flex;
    line-height: 0.24rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.65);
    .buy-button {
      margin-right: 0.23rem;
      width: 1.48rem;
      height: 0.4rem;
      background: var(--backgroundColor);
      border-radius: 0.02rem;
      font-weight: 500;
      color: #ffffff;
      line-height: 0.4rem;

      font-size: 0.16rem;
      text-align: center;
    }
  }

  /deep/ .el-tabs__item {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    font-size: 0.16rem;
  }
  /deep/ .el-tabs__nav-wrap::after {
    height: 0.01rem;
    background: #e4ccad;
  }
  /deep/ .el-tabs__active-bar {
    background: var(--backgroundColor);
  }
  /deep/ .el-tabs__content {
    font-size: 0.14rem;

    font-weight: 400;
    // color: rgba(0, 0, 0, 0.45);
    line-height: 0.22rem;
  }
}
@media (max-width: 768px) {
  .index-body {
    margin: 0;
    .mian-body {
      margin: 0;
      top: 0;
      padding: 0;
      height: auto;
    }
    .sidebar {
      display: none;
    }
    .sidebar2 {
      display: none;
      position: fixed;
      top: 0;
      width: 100vw;
      height: 100vh;

      background: rgba(0, 0, 0, 0.65);
      z-index: 19;
      .sidebarInner {
        width: 3rem;
        height: 100vh;
        background: #ffffff;
        border-radius: 0rem 0.16rem 0.16rem 0rem;
        overflow: scroll;
      }
      .sidebarTop {
        margin-top: 0.6rem;
        margin-bottom: 0.07rem;
        margin-left: 0.3rem;
        display: flex;
        align-items: center;
        span {
          // width: 0.64rem;
          height: 0.24rem;
          font-size: 0.16rem;

          font-weight: 500;
          color: rgba(0, 0, 0, 0.9);
          line-height: 0.24rem;
          margin-left: 0.62rem;
        }
      }

      .buttons {
        margin: 0 0.56rem;
        margin-top: 0.32rem;
        display: flex;
        justify-content: space-between;
        .reset {
          width: 0.86rem;
          height: 0.32rem;
          background: #fff4f4;
          border-radius: 0.04rem;

          font-size: 0.14rem;

          font-weight: 400;
          color: #c3282b;
          line-height: 0.32rem;
          text-align: center;
          // visibility: hidden;
        }
        .confirm {
          width: 0.86rem;
          height: 0.32rem;
          background: #c3282b;
          border-radius: 0.04rem;
          text-align: center;
          font-size: 0.14rem;

          font-weight: 400;
          color: #ffffff;
          line-height: 0.32rem;
        }
      }
    }
    .container {
      margin: 0;
      padding: 0;
      flex-wrap: wrap;
      height: auto;
      width: auto;
      background: #ffffff;
      .button {
        width: 3.75rem;
        height: 0.56rem;
        background: #ffffff;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 9;
        position: relative;
        div {
          background: #ffffff;
          // width: 0.96rem;
          justify-content: flex-end;
          span {
            font-size: 0.14rem;
            color: rgba(0, 0, 0, 0.9);
          }
        }
        .select {
          display: flex;
          flex-grow: 1;
          justify-content: flex-start;
          margin-left: 0.16rem;
          align-items: center;
          span {
            margin-left: 0.08rem;
          }
          svg {
            fill: var(--backgroundColor);
          }
        }
        .time {
          margin-right: 0.22rem;
          display: flex;
          align-items: center;
          // visibility: hidden;
          figure {
            width: 0.78rem;
            height: 0.28rem;
            border: none;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        svg {
          width: 100 !important;
          height: 100 !important;
        }
      }
    }
    figure {
      height: 4.2rem;
      width: 100vw;
    }
    .content {
      display: flex;
      flex-direction: column;
      flex-direction: column-reverse;
      margin: 0.12rem 0.16rem;
      width: auto;
      .price {
        // order: -1;
        margin: 0;
        width: 0.88rem;
        height: 0.36rem;
        font-size: 0.24rem;

        font-weight: 500;
        color: #c3282b;
        line-height: 0.36rem;
        span {
          content: "";
          display: none;
        }
        .buy-button {
          display: none;
        }
      }
      .price::before {
        content: "￥";
        font-size: 0.12rem;

        line-height: 0.44rem;
      }
      .source {
        margin: 0.12rem 0;
        padding: 0;
        display: flex;
        width: auto;
        background: #ffffff;
        height: auto;

        li {
          width: auto;
          padding: 0 0.08rem;
          height: 0.24rem;
          background: #f3e3e3;
          border-radius: 0.02rem;
          text-align: center;
          line-height: 0.24rem;
          margin: 0;
          margin-right: 0.08rem;
        }
        li:nth-child(2n) {
          display: none;
        }
      }
      .title {
        width: 3.43rem;

        font-size: 0.16rem;

        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 0.24rem;
        margin-top: 0.12rem;
        margin-bottom: 0.24rem;
      }
      .ibsn {
        display: inline;
        padding: 0 0.08rem;

        line-height: 0.24rem;
        font-size: 0.12rem;

        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);

        background: #eeeeee;
        border-radius: 0.02rem;
        margin-bottom: 0.26rem;
      }
      .synopsis {
        order: -1;
        position: relative;
        padding-top: 0.34rem;
        margin-bottom: 0.24rem;
      }
      .synopsis::before {
        content: "";
        display: block;
        height: 0.1rem;
        width: 100vw;
        position: absolute;
        top: 0;
        left: -0.16rem;
        background: #f6f6f6;
      }
      /deep/ .el-tabs__nav-wrap::after {
        background: #eeeeee;
      }
    }
  }
  .brief-introduction {
    display: none;
    position: absolute;
    height: 3.13rem;
    width: auto;
    top: 1.1rem;
    left: 0;
    z-index: 9;
    margin: 0;
    flex-direction: column-reverse;
    height: auto;

    figure {
      display: none;
    }
    .infoTitle {
      display: none;
    }
    .infomsg {
      margin: 0rem 0.16rem;
      border-bottom: 0.01rem solid #eeeeee;
      padding: 0.24rem 0;
    }
    .title {
      z-index: 9;
      margin: 0;
      padding: 0;
      width: auto;
      // margin: .24rem 0;
      font-size: 0.14rem;
      background: #ffffff;

      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 0.22rem;
    }
    .info {
      z-index: 9;
      margin: 0;
      padding: 0;
      width: auto;
      background: #ffffff;
      ul {
        margin-top: 0.24rem;
        margin-bottom: 0.24rem;
        li {
          font-size: 0.14rem;
          width: 3rem;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 0.22rem;
          margin-bottom: 0.08rem;
        }
      }
    }
  }
  .brief-introduction::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;

    background: rgba(0, 0, 0, 0.65);
    width: 100vw;
    height: 100vh;
  }
  /deep/ .el-submenu__title {
    height: 0.48rem;
  }
  .mian-body .topone {
    height: 0.48rem;
  }
  /deep/ .el-icon-arrow-left::before {
    content: "\e6de";
    color: var(--backgroundColor);
    font-size: 0.2rem;
    font-weight: 600;
  }
  .banner {
    display: none;
  }
}
</style>
